@import '../../styles/utils.scss';

.el {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: calc(100% - 2px);
  :global(.slick-slide) {
    width: 100%;
  }
  .sliderWrapper {
    :global(.slick-track) {
      display: flex;
      @include desktop-sm {
        align-items: end;
      }
    }
    :global(.slick-slide) {
      height: inherit;
      > div {
        height: 100%;
      }
    }
    :global(.slick-arrow) {
      position: absolute;
      bottom: 48px;
      left: 50%;
      width: 50px;
      height: 20px;
      margin-left: -25px;
      :global(.bar) {
        width: 100%;
        height: 2px;
        background-color: $color-blue-25;
        display: inline-block;
      }
      z-index: 100;
      &:global(.slick-next) {
        transform: translate(25px, 0);
      }
      &:global(.slick-prev) {
        transform: translate(-25px, 0);
      }
      &:global(.slick-disabled .bar) {
        background-color: $color-blue;
      }
    }
  }
  .quoteWrapper {
    margin-top: 59px;
    position: relative;
    height: 100%;
    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 10px;
      width: 120px;
      height: 90px;
      background-image: url('/images/quote-background-top.png');
      background-position: top left;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .quoteAuthorImageWrapper {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    border-radius: 80px;
    overflow: hidden;
    margin-right: 20px;
    .quoteAuthorImage {
      object-fit: cover;
      width: 100%;
    }
  }
  .quoteAuthorDetails {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
    align-items: center;
  }
  .quoteRow {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    @include desktop-l {
      flex-direction: column;
      align-items: baseline;
      .quoteLinkedIn {
        margin-top: 30px;
      }
    }
    @include mobile {
      flex-direction: column;
      align-items: baseline;
      .quoteLinkedIn {
        margin-top: 30px;
      }
    }
  }
  .quoteLinkedIn {
    width: 228px;
    height: 43px;
    background: $color-orange;
    border-radius: 50px;
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 43px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    text-align: right;
    text-transform: uppercase;
    position: relative;
    padding-right: 20px;
    cursor: pointer;
  }
  .linkedInIcon {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .quoteAuthorName {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: $color-blue;
  }
  .quoteAuthorRole {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: -0.03em;
    color: $color-blue;
    text-transform: uppercase;
    margin-top: 5px;
    display: inline-block;
  }
  .quote {
    padding: 48px;
    padding-bottom: 88px;
    color: $color-orange;
    border: 1px solid #E9E9EF;
    box-sizing: border-box;
    margin-top: 60px;
    height: calc(100% - 120px);
    width: calc(100% - 5px);
    @include desktop-sm {
      height: auto;
    }
    @include tablet {
      padding: 40px 23px;
      padding-bottom: 100px;
      height: auto;
    }
    @include mobile {
      height: auto;
      padding-bottom: 100px;
    }
    &-title {
      font-size: 28px;
      font-style: italic;
      font-weight: 500;
      line-height: 1.1;
      letter-spacing: -0.03em;
      text-align: left;
      position: relative;
      padding-bottom: 20px;
      @include tablet {
        font-size: 24px;
        font-style: italic;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.03em;
        text-align: left;
      }
      &:after {
        content: ' ';
        position: absolute;
        right: -20px;
        bottom: 0;
        width: 120px;
        height: 90px;
        background-image: url('/images/quote-background-bottom.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
      }
    }

    &-author {
      margin-top: 8px;
      font-size: 18px;
      @include tablet {
        font-size: 14px;
      }
    }

    &-button {
      background: $color-orange;
      border-radius: 50px;
      color: white;
      display: inline-block;
      padding: 15px 20px 11px 42px;
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin-top: 20px;
      @include tablet {
        margin: 20px auto 0 auto;
      }
      &:hover {
        opacity: 0.8;
      }
      span {
        font-size: 22px;
        color: white;
        margin-right: 5px;
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }
  }
  .name {
    font-weight: 600;
    font-size: 50px;
    line-height: 1.2;
    letter-spacing: -0.03em;
    @include tablet {
      font-size: 36px;
    }
  }
}
