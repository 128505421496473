.slickArrowsStandard {
  display: flex;
  justify-content: flex-start;
  min-height: 40px;

  .slickArrow {
    display: block;
    font-size: 24px;
    color: $color-orange;
    padding: 10px 14px 6px;
    cursor: pointer;

    &.nextBtn {
      border-left: 1px solid $color-blue-25;
    }

    &.prevBtn {
      transform: scaleX(-1);
    }

    &.disabled {
      pointer-events: none;
      
      div {
        opacity: 0.2;
      }
    }
  }
}

// Styles used with @extend in multiple components
.globalCardTitle {
  color: #272560;
  font-weight: 600;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.03em;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
