@import '../../styles/utils.scss';
@import '../../styles/componentShared.scss';

.el {
    width: 100%;
    overflow: hidden;
    max-width: $container-fhd;
    margin: 0 auto;

    &.noBorder {
      border-bottom: none;
    }

    &.peopleList{
      background-color:$color-grey;
    }

    @include tablet {
      margin-top: 0;
    }

    .peopleImg {
      overflow: hidden;
      position: relative;

      display: inline-block;
      width: 100%;
      color: $color-blue;
      padding-bottom: 60px;
      background-color: #fff;
      transition: all 0.5s;

      &:hover {
        background-color: $color-blue;
        color: #fff;

        .imageWrap .image {
          transform: scale(1.1);
        }
      }
    }

    .outerImageWrap {
      opacity: 0;
      transition: 0.2s;

      &.loaded {
        opacity: 1;
      }
    }

    .imageWrap {
      position: relative;
      overflow: hidden;

      &:after {
        content: ' ';
        display: block;
        width: 100%;
        padding-top: 133.45%;
      }

      .image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.3s ease-in-out;
        transform: translate3d(0,0,10px);
      }

      img {
        transition: 0.3s ease-in-out;
        object-fit: cover;
      }
    }

    .peopleName {
      position: absolute;
      bottom: 0;
      height: 60px;
      z-index: 1;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      letter-spacing: -0.03em;
      padding:0px 12px;
      display: inline-flex;
      align-items: center;
      max-width: 170px;

      @include mobile {
        font-size: 14px;
      }
    }
    
    &.diffLayout {
      background-color:$color-grey;
      margin-bottom: 100px;

      &:after {
        content: " ";
        width: 100%;
        max-width: $container-fhd;
        position: absolute;
        height: 40px;
        background-color: #f7f7f7;
        margin-top: 1px;
      }
      &.noBorder:after {
        margin-top: 0;
      }
    }
}

.carouselOverrides {
  :global(.slick-slide) {
    width: 219px;
    padding-right: 36px;

    @include tablet {
      height: auto;
      width: 203px;
      padding-right: 20px;
      & > div {
        height: 100%;
      }
    }
    
    @include mobile {
      width: 140px;
    }
  }
}
